export interface Notification {
  id: number;
  categoryId: NotificationsCategoryType;
  title: string;
  message: string;
  nickName: string | null;
  thumbnailUrl: string;
  thumbnailIcon: string | null;
  isRead: boolean;
  createdAt: string;
  action: NotificationAction;
}

export const enum NotificationsCategoryType {
  NEW_CONTENT = 1,
  REPLY = 2,
  NEWS = 3,
}

interface NotificationAction {
  type: 'LINK';
  url: string;
}

export interface NotificationsCategory {
  id: NotificationsCategoryType;
  name: string;
}

export type NotificationsCategorySelectableId = number | 'ALL';
export interface NotificationsCategorySelectable extends Omit<NotificationsCategory, 'id'> {
  id: NotificationsCategorySelectableId;
}

import ASYNC_DATA_KEYS from '~/constants/async-data-keys';

import { notificationsService } from '../service';
import type { NotificationsCategory, NotificationsCategorySelectable } from '../types';

export const useNotificationsCategories = () => {
  return useAsyncDataWrapper(
    ASYNC_DATA_KEYS[`/v1/notifications/categories`](),
    async () => {
      const res = await notificationsService.getNotificationsCategories();
      return parseNotificationsCategoriesToSelectable(res);
    },
    { server: false },
  );
};

const parseNotificationsCategoriesToSelectable = (
  items: NotificationsCategory[],
): NotificationsCategorySelectable[] => {
  return [
    {
      id: 'ALL',
      name: '전체',
    },
    ...items,
  ];
};

<script setup lang="ts">
import type { Notification } from '../types';
import NotificationItem from './NotificationItem.vue';

defineProps<{
  items: Notification[];
}>();

const emit = defineEmits<{
  (e: 'clickItem', item: Notification): void;
}>();
</script>

<template>
  <template v-for="item of items" :key="String(item.id)">
    <NotificationItem :item="item" @click="emit('clickItem', item)" />
  </template>
</template>

import { createContext } from 'radix-vue';
import type { Ref } from 'vue';

import type { Notification, NotificationsCategorySelectableId } from './types';

export interface NotificationsContext {
  notifications: Ref<{
    today: Notification[];
    recentSevenDays: Notification[];
    recentThirtyDays: Notification[];
    afterThirtyDays: Notification[];
    all: Notification[];
  } | null>;
  unreadCounts: Ref<number>;
  selectedCategory: Ref<NotificationsCategorySelectableId>;
  isLastPage: Ref<boolean>;
  pendingNotifications: Ref<boolean>;
  errorNotifications: Ref<string>;
  showNextPage: () => void;
  changeCategory: (newCategory: NotificationsCategorySelectableId) => void;
  refreshNotifications: () => Promise<void>;
  refreshUnreadCounts: () => Promise<void>;
}

export const [injectNotificationsContext, provideNotificationsContext] =
  createContext<NotificationsContext>('Notifications');

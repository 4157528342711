import BaseService from '~/services/BaseService';

import type { Notification, NotificationsCategory } from './types';

class NotificationsService extends BaseService {
  constructor() {
    super('');
  }

  getNotifications(page?: number, size?: number, categoryId?: number) {
    return this.getV2<{ content: Notification[]; last: boolean }>('/users/notifications', {
      params: { page, size, categoryId },
      useKotlinBaseUrl: true,
    });
  }

  getNotificationsCounts() {
    return this.getV2<{ count: number }>('/users/notifications/counts', {
      useKotlinBaseUrl: true,
    });
  }

  getNotificationsCategories() {
    return this.getV2<NotificationsCategory[]>('/notifications/categories', {
      useKotlinBaseUrl: true,
    });
  }

  patchNotificationsRead(notificationId: number) {
    return this.patchV2(`/users/notifications/${notificationId}/read`, { useKotlinBaseUrl: true });
  }

  patchNotificationsReadAll() {
    return this.patchV2('/users/notifications/read-all', { useKotlinBaseUrl: true });
  }
}

export const notificationsService = new NotificationsService();

<script setup lang="ts">
import IndexedDB from '~/libs/IndexedDB';
import Tracker from '~/libs/Tracker';

import { Button, IconX } from '~/components/5x';

import { useNotificationsCategories } from '../composables/useNotificationsCategories';
import { NotificationsCategoryTypeKoreanRecord } from '../constants';
import { injectNotificationsContext } from '../context';
import { notificationsService } from '../service';
import type { Notification } from '../types';
import NotificationItems from '../ui/NotificationItems.vue';
import NotificationsDivider from '../ui/NotificationsDivider.vue';
import NotificationsErrorScreen from '../ui/NotificationsErrorScreen.vue';
import NotificationsLoadingScreen from '../ui/NotificationsLoadingScreen.vue';
import { RightSheetClose } from '../ui/right-sheet';

const router = useRouter();

const {
  notifications,
  selectedCategory,
  isLastPage,
  pendingNotifications,
  errorNotifications,
  showNextPage,
  changeCategory,
  refreshNotifications,
  refreshUnreadCounts,
} = injectNotificationsContext();

const { isDesktop } = useMobile();
const { data: categories } = await useNotificationsCategories();

const isLoading = ref(false);

const handleReadOne = async ({ id, categoryId, action }: Notification) => {
  if (isLoading.value) {
    return;
  }
  isLoading.value = true;
  try {
    Tracker['Click Notification Center']({ notificationType: NotificationsCategoryTypeKoreanRecord[categoryId] });
    await notificationsService.patchNotificationsRead(id);
    refreshNotifications();
    refreshUnreadCounts();
    if (isDesktop.value) {
      window.open(action.url, '_blank');
    } else {
      router.push(action.url);
    }
  } catch (e) {
    const err = e as { data: { message: string } };
    if (err.data.message) {
      await $alert(err.data.message);
      // 알림에 문제가 있을 경우 해당 알림을 백엔드에서 삭제할 수 있어서, 리프레시 필요.
      refreshNotifications();
      refreshUnreadCounts();
      return;
    }
    console.error(e);
    throw e;
  } finally {
    isLoading.value = false;
  }
};

const handleReadAll = async () => {
  try {
    await notificationsService.patchNotificationsReadAll();
    refreshNotifications();
    refreshUnreadCounts();
  } catch (e) {
    $alert((e as { data: { message: string } }).data.message);
  }
};

const handleGoBack = () => {
  const previousPath = sessionStorage.getItem('prevPath');
  if (!previousPath || previousPath === router.currentRoute.value.fullPath) {
    router.push($getRoute().index());
    return;
  }
  router.back();
};

onMounted(() => {
  // 알림 페이지 진입 시 데이터 갱신
  refreshNotifications();
  refreshUnreadCounts();
});

// PWA 앱푸시 용도 사용 추정
// PWA에서 알람을 클릭한 경우 로깅하는 로직으로 추정
const checkIndexedDBForNotificationClickType = async () => {
  try {
    const notificationType = await IndexedDB.read('notificationType');
    if (notificationType) {
      Tracker['Click Navigation']({
        menuType: 'GNB',
        name: '알림',
      });
      IndexedDB.delete('notificationType');
    }
  } catch (err) {
    console.error(err);
  }
};

// PWA 앱푸시 용도 사용 추정
// PWA 에서 뜬 알람을 클릭하면 IndexDB에 저장되고, 이걸 꺼내서 쓰기 위함으로 판단
const checkIndexedDBForNotificationClick = async () => {
  const retrievedData = await IndexedDB.read('notificationclick');
  if (retrievedData) {
    if (notifications.value?.today[0]?.id) {
      await notificationsService.patchNotificationsRead(notifications.value?.today[0]?.id);
      refreshNotifications();
      refreshUnreadCounts();
    }
    IndexedDB.delete('notificationclick');
  }
};

onMounted(() => {
  checkIndexedDBForNotificationClickType();
  checkIndexedDBForNotificationClick();
});

onMounted(() => {
  Tracker['Click Navigation']({ menuType: 'GNB', name: '알림' });
});
</script>

<template>
  <header
    class="sticky top-0 bg-neutral-000 z-[10] flex items-center justify-between h-[70px] pl-2 pr-5 border-b border-neutral-alpha-200">
    <div class="flex items-center gap-1">
      <ClientOnly v-if="isDesktop">
        <RightSheetClose as-child>
          <Button size="icon-md" color="neutral" variant="ghost">
            <IconX />
          </Button>
        </RightSheetClose>
      </ClientOnly>

      <Button v-else size="icon-md" color="neutral" variant="ghost" @click="handleGoBack">
        <IconX />
      </Button>
      <span class="neutral-1000 text-lg font-semibold">알림</span>
    </div>
    <Button variant="ghost" color="neutral" size="lg" class="text-neutral-700" @click="handleReadAll">
      전체읽음
    </Button>
  </header>

  <section class="flex items-center flex-wrap gap-1.5 px-4 mt-4">
    <div v-if="!categories" class="h-10" />
    <span
      v-for="category of categories"
      :key="category.id"
      :class="{
        'bg-primary-100 border-primary-600 text-primary-600': selectedCategory === category.id,
        'border-neutral-alpha-200 text-neutral-800': selectedCategory !== category.id,
      }"
      class="flex items-center px-3 h-10 rounded-full border font-semibold cursor-pointer"
      @click="changeCategory(category.id)">
      {{ category.name }}
    </span>
  </section>

  <NotificationsLoadingScreen v-if="pendingNotifications" />

  <NotificationsErrorScreen v-else-if="errorNotifications" />

  <div
    v-else-if="notifications?.all.length === 0"
    class="text-md text-neutral-500 text-center font-semibold py-[200px]">
    새로운 알림이 없습니다
  </div>

  <template v-else>
    <NotificationsDivider v-if="notifications?.today.length" title="오늘" />

    <NotificationItems :items="notifications?.today ?? []" @click-item="(item) => handleReadOne(item)" />

    <NotificationsDivider v-if="notifications?.recentSevenDays.length" title="최근 7일" />

    <NotificationItems :items="notifications?.recentSevenDays ?? []" @click-item="(item) => handleReadOne(item)" />

    <NotificationsDivider v-if="notifications?.recentThirtyDays.length" title="최근 30일" />

    <NotificationItems :items="notifications?.recentThirtyDays ?? []" @click-item="(item) => handleReadOne(item)" />

    <Observer :key="String(isLastPage)" class="h-10" @intersect="showNextPage" />
  </template>
</template>

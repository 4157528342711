<script setup lang="ts">
import { Button } from '~/components/5x';

const handleRefresh = () => {
  window.location.reload();
};
</script>

<template>
  <div class="text-center py-[200px]">
    <p class="text-md text-neutral-800 font-semibold">알림을 불러오던 중 문제가 생겼어요</p>
    <p class="text-neutral-500">새로고침 해주세요.</p>
    <Button class="mt-3" variant="outline" color="neutral" @click="handleRefresh">새로고침</Button>
  </div>
</template>

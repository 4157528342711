<script setup lang="ts">
import type { Component } from 'vue';
import { getTimeDifference } from '~~/src/libs/dateUtils';

import { IconBell, IconCircleMessage, IconCornerDownRight, IconPencil, IconUser } from '~/components/5x';

import { type Notification } from '../types';

const iconsRecord: Record<string, Component> = {
  IconCornerDownRight: IconCornerDownRight,
  IconBell: IconBell,
  IconCircleMessage: IconCircleMessage,
  IconPencil: IconPencil,
  IconUser: IconUser,
};

defineProps<{ item: Notification }>();
</script>

<template>
  <div class="text-neutral-800 px-4 py-3 hover:bg-neutral-200 cursor-pointer">
    <div class="flex items-start justify-between gap-4" :class="{ 'opacity-30': item.isRead }">
      <div class="shrink-0 relative mt-[5px]">
        <UserAvatar class="w-10 h-10 flex" :image="item.thumbnailUrl" />
        <div
          v-if="item.thumbnailIcon"
          class="absolute w-6 h-6 -bottom-1.5 -right-2 rounded-full bg-neutral-000 flex items-center justify-center">
          <component :is="iconsRecord[item.thumbnailIcon]" size="16" />
        </div>
      </div>
      <div class="grow overflow-hidden">
        <p class="text-neutral-600">
          <span v-if="item.nickName">{{ item.nickName }}</span>
          <span v-if="item.nickName"> · </span>
          <span>{{ getTimeDifference(item.createdAt) }}</span>
        </p>
        <p class="text-neutral-900 font-semibold text-ellipsis whitespace-nowrap overflow-hidden">{{ item.title }}</p>
        <p class="mt-0.5 line-clamp-3">{{ item.message }}</p>
      </div>
    </div>
  </div>
</template>
